import { PageLayout } from 'next'
import dynamic from 'next/dynamic'
import { SiteFooter } from '../../components/site-footer'
import { SiteHeader } from '../../components/site-header'
import HubSpotChat from '~/components/hubspot-chat'

const LiveSession = dynamic(() => import('~/components/live-session'), {
  ssr: false,
})

export const HomepageLayout: PageLayout = page => {
  return (
    <div className="overflow-x-hidden">
      <SiteHeader />
      {page}
      <SiteFooter />
      <HubSpotChat />
      <LiveSession />
    </div>
  )
}
