
import Script from 'next/script';
import { useEffect } from 'react';
import { env } from '~/config/env';
import { useAuth } from '~/context/auth';
import { accessToken } from '~/utils/access-token';

export const HubSpotChat = () => {
  const { user } = useAuth()

  const onHubSpotConversationReady = () => {
      window.HubSpotConversations!.widget.load()
  }

  const loadHubSpotWidgetWhenReady = () => {
    if (window.HubSpotConversations) {
      onHubSpotConversationReady()
    } else {
      window.hsConversationsOnReady = [onHubSpotConversationReady]
    }
  }

  useEffect(() => {
    // This has to be set before the Chat Widget is loaded
    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    if (user) {
      const restApiAccessToken = accessToken.get('dashboard')
      fetch(`${env.apiUrl}/rest/hubspot/conversation-identification-token`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${restApiAccessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => data.token)
        .then(token => {
          window.hsConversationsSettings['identificationToken'] = token
          window.hsConversationsSettings['identificationEmail'] = user.email
          loadHubSpotWidgetWhenReady()
        })
        .catch(error => {
          console.error(error)
        })
    } else{
        loadHubSpotWidgetWhenReady()
     }

    return () => {
      if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        window.HubSpotConversations.widget.remove()
      }
    }
  }, [user])

  return (
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js-na1.hs-scripts.com/23534435.js"
      />
  )
}

export default HubSpotChat
